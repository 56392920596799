<template>
  <div class="bg-white">
    <div class="flex h-full flex-col">
      <tools-notification/>

      <div class="flex flex-col min-h-screen">
        <navigation-top-nav/>

        <slot/>

        <navigation-footer class="mt-auto"/>
      </div>
    </div>
  </div>
</template>


<style>
html {
  @apply h-full bg-gray-50 antialiased;
}

.link {
  @apply text-indigo-500 hover:text-indigo-700 cursor-pointer;
}

.link-gray {
  @apply text-gray-800 hover:text-indigo-700 cursor-pointer;
}

.for-mobile {
  @apply px-3 sm:px-6
}

.input {
  @apply appearance-none block w-full px-3 py-2 border border-gray-200 rounded-md shadow-sm placeholder-gray-400 sm:text-sm
}

.button-primary {
  @apply py-3 rounded-md text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700
}
</style>
<script lang="ts" setup>
</script>