<template>
  <footer class="bg-white">
    <div class="mx-auto max-w-md sm:max-w-7xl px-8">
      <div class="flex flex-col sm:flex-row items-center justify-between">
        <div class="flex items-center space-x-3">
          <img v-img-error :alt="`${$global.name} logo`" class="h-14" src="/logo1.png"/>

          <span class="text-gray-700">
              Në bashkëpunim me
            </span>

          <img v-img-error :alt="`${$global.name} logo`" class="h-10 rounded-xl" src="/logo2.png"/>
        </div>

<!--        <p class="text-base text-gray-500">
          {{ $t('general.a_product_from') }}
          <a class="text-indigo-600" href="https://mepelqen.al">mepelqen.al</a>.
        </p>-->

        <div class="flex items-center space-x-3">
          <span class="text-gray-700">
            {{ $t('general.follow_us') }}
          </span>

          <a class="text-decoration-none" v-for="social in $global.socials"
             :href="social.url">
            <icon :name="social.icon" size="20"/>
          </a>
        </div>
      </div>

      <div class="mt-12 border-t border-gray-200 py-8 space-x-4 text-center">
        <nuxt-link to="/privacy-policy">
          {{ $t('general.privacy_policy') }}
        </nuxt-link>

        <nuxt-link to="/terms-of-service">
          Terms of Service
        </nuxt-link>
      </div>

      <div class="text-base text-gray-400 xl:text-center pb-4">
        &copy; {{ new Date().getFullYear() }} {{ $t('general.all_rights_reserved', {domain: $global.name}) }}
      </div>
    </div>
  </footer>
</template>
