<template>
  <header>
    <nav>
      <div class="mx-auto max-w-7xl relative z-50 flex justify-between p-8">
        <div class="relative z-10 flex items-center gap-16">
          <nuxt-link class="flex items-center space-x-2 font-semibold text-sm" to="/">
            <img v-img-error :alt="`${$global.name} logo`" class="h-14" src="/logo1.png"/>

            <span class="text-gray-700">
              Në bashkëpunim me
            </span>

            <img v-img-error :alt="`${$global.name} logo`" class="h-10 rounded-xl" src="/logo2.png"/>
          </nuxt-link>

          <div class="hidden lg:flex lg:gap-10">
            <nuxt-link
                v-for="menu_item in menu_items"
                :to="menu_item.to"
                class="relative -mx-3 -my-2 rounded-lg px-3 py-2 text-sm text-gray-700 transition-colors delay-150 hover:text-gray-900 hover:delay-0">
              <span class="relative z-10">
                {{ $t(menu_item.label) }}
              </span>
            </nuxt-link>

            <nuxt-link
                class="relative -mx-3 -my-2 rounded-lg px-3 py-2 text-sm text-gray-700 transition-colors delay-150 hover:text-gray-900 hover:delay-0"
                to="/register">
              <span class="relative z-10">
                Regjistrohu
              </span>
            </nuxt-link>
          </div>
        </div>

        <div class="flex items-center gap-6">
          <div class="lg:hidden">
            <button class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                    @click="open_menu = !open_menu">
              <icon name="icon-park:hamburger-button" size="32"/>
            </button>
          </div>

          <div
              style="position:fixed;top:1px;left:1px;width:1px;height:0;padding:0;margin:-1px;overflow:hidden;clip:rect(0, 0, 0, 0);white-space:nowrap;border-width:0;display:none"></div>
          <nuxt-link v-if="origin === 'https://thelisting.app'"
                     class="inline-flex justify-center rounded-lg py-2 px-3 text-sm font-semibold outline-2 outline-offset-2 transition-colors bg-gray-800 text-white hover:bg-gray-900 active:bg-gray-800 active:text-white/80 hidden lg:block"
                     to="/#join">
            {{ $t('join') }}
          </nuxt-link>

          <nuxt-link v-else
                     class="inline-flex justify-center rounded-lg border py-[calc(theme(spacing.2)-1px)] px-[calc(theme(spacing.3)-1px)] text-sm outline-2 outline-offset-2 transition-colors border-gray-300 text-gray-700 hover:border-gray-400 active:bg-gray-100 active:text-gray-700/80 hidden lg:block"
                     to="/login">
            {{ $t('general.login') }}
          </nuxt-link>
        </div>
      </div>
    </nav>

    <div v-if="open_menu" class="fixed inset-0 z-50 overflow-y-auto bg-white px-6 py-6 lg:hidden">
      <div class="flex h-9 items-center justify-between">
        <div class="flex">
          <nuxt-link to="/">
            <img v-img-error :alt="`${$global.name} logo`" class="h-10" src="/logo.png">
          </nuxt-link>
        </div>

        <div class="flex">
          <button class="inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                  type="button"
                  @click="open_menu = false">
            <icon name="humbleicons:times" size="28"/>
          </button>
        </div>
      </div>

      <div class="mt-6 flow-root">
        <div class="-my-6 divide-y divide-gray-500/10">
          <div class="py-6 space-y-2">
            <nuxt-link
                v-for="menu_item in menu_items"
                :to="menu_item.to"
                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-400/10">
              {{ $t(menu_item.label) }}
            </nuxt-link>

            <nuxt-link
                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-400/10"
                to="/register">
              Regjistrohu
            </nuxt-link>
          </div>

          <div class="flex flex-row justify-between py-6">
            <nuxt-link v-if="origin === 'https://thelisting.app'"
                       class="-mx-3 rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-gray-900 hover:bg-gray-400/10"
                       to="/#join">
              {{ $t('join') }}
            </nuxt-link>

            <nuxt-link v-else
                       class="-mx-3 rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-gray-900 hover:bg-gray-400/10"
                       to="/login">
              {{ $t('general.login') }}
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
const menu_items = ref([
  {
    label: 'Home',
    to: '/'
  },
  /*{
    label: 'blog',
    to: '/blog'
  },*/
  {
    label: 'general.features',
    to: '/#features'
  },
  /*{
    label: 'demo',
    to: '/#demo'
  },*/
  /*{
    label: 'join',
    to: '/#join'
  },*/
  /*{
    label: 'general.pricing',
    to: '/#pricing'
  },*/
])
const open_menu = ref(false)
const route = useRoute()

onUnmounted(() => document.body.classList.remove('overflow-hidden'))

const origin = computed(() => {
  return process.client ? window.location.origin : ''
})

watch(open_menu, value => {
  if (value) {
    document.body.classList.add('overflow-hidden')
  } else {
    document.body.classList.remove('overflow-hidden')
  }
})

watch(route, () => {
  open_menu.value = false
})
</script>
